/* Main.css */
.bg-cover {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9 !important;
  z-index: 10;
}

.bg-cover:hover .overlay {
  transition: opacity 0.3s ease;
  opacity: 0 !important;
  cursor: pointer;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
 
}


@media (max-width: 767px) {
  .bg-cover {
    height: 33.33vh;
  }
  .logoimg{
    width: 130px !important;
  }
  .branchname{
    font-size: 25px !important;
  }
}
.logo{
  position: absolute;
  z-index: 16;
  top: 36%;
  left: 27%;
}
.logoimg{
  width: 200px ;
}
.branchname{
  font-size: 38px;
  margin-top: 10px;
  font-family: "Denk One", sans-serif;

    font-weight: 600;
    font-style: normal;


}